.warning-box {
    font-size: 22px;
    // background-color: #fde6a18d;
    padding: 10px;
    border: 1px solid #FFC107;
    margin: 0 10px 20px;
    border-radius: 10px;
    color: #FFC107;
    p{
        margin: 0 0 0.3em;
    }
    .subtext{
        font-size: 0.7em;
    }
}
