.opening {
    display: grid;
    grid-template-columns: 4.5em 1.5fr 1fr;
    font-size: 17px;
    padding: 10px 0;
    border-radius: 10px;
    color: #ecedf0;
    text-decoration: none;
    gap: 5px;

    &:hover{
        background-color: #fff1;
    }
    
    @media only screen and (max-width: 1000px) {
        font-size: 17px;
        grid-template-columns: 4.5em 1.5fr;
        padding: 0.7em 15px 0.5em 0;
        margin: 0;
        border-width: 0 0 1px;
        border-radius: 0;
        .opening-title{
            width: 100%;
        }
        .stacks{
            grid-column: 1 / -1;
            text-align: left !important;
            padding: 4px 0.5em 0 !important;
        }
        h4{
            display: none !important;
        }
        h4 svg{
            display: none;
        }
    }

    .opening-main {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        padding: 0 0 5px;
        overflow: hidden;

        svg{
            vertical-align: bottom;
            margin: 0 0.2em;
            font-size: 0.9em;
        }

        h3, h4{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        h3 {
            font-size: 1em;
            margin: 0;
            font-weight: 500;
        }

        h4 {
            font-size: .95em;
            margin: 0;
            font-weight: normal;
            color: #eee;
        }
    }

    .opening-info{
        align-self: center;
        display: flex;
        width: 100%;
        font-size: .9em;
        color: #c1c1c1;
        span{
            margin: 0 0.6em 0 0;
        }
        svg{
            margin: 0 0.2em 1px 0px;
            color: #ccc;
        }
        .seen{
            color: #008a3c;
            border-radius: 5px;
            margin: 0 0.2em 0 0;
            svg{
                margin: 0;
            }
        }
        .salary-range{
            color: #008a3c;
            font-weight: bold;
            // margin: -4px 0.6em -4px -1px;
            // border-radius: 5px;
        }
    }

    .stacks {
        padding: 0 1em 0 0;
        text-align: right;
    }

    .stack {
        display: inline-block;
        font-size: 0.8em;
        color: #fff;
        padding: 3px 0.6em;
        border-radius: 20px;
        margin: 0.2em;
        //color: #fff;
        font-weight: 700;
        filter: brightness(0.87);
    }

}