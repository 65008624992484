.save-search {
  position: fixed;
  bottom: 0;
  background-color: rgb(49 49 49 / 90%);
  backdrop-filter: blur(5px);
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  left: 0;
  border-radius: 20px 20px 0px 0px;
  right: 0;
  gap: 0 10px;
  font-size: 16px;
  padding: 10px;
  transition: 0.2s ease bottom;
  &.closed{
    bottom: -80px;
  }
  .grid {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: center;
    gap: 0 30px;
  }
  h5 {
    margin: 0;
    font-size: 20px;
  }
  input {
    background-color: #fff;
    color: #0b3f8d;
    border-radius: 12px;
    align-items: center;
    padding: 0.25em 0.5em;
    font-size: 20px;
    border: 1px solid #0b3f8d;
    &::placeholder {
      color: #0b3f8d;
    }
  }
  .subscribe {
    border: 0px;
    margin: 0;
    background-color: #0b3f8d;
    border-radius: 12px;
    padding: 7px 10px;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
    margin: 0 15px;
  }
  .close{
    color: #dedede;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    margin: 10px 20px;
    border: 0px;
    background-color: transparent;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    aspect-ratio: 1/1;
    padding: -12px;
    border-radius: 50%;
    transition: 0.12s ease all;
    &:hover{
        color: #4a92fd;
        border: 2px solid;
    }
  }
}
