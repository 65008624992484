.company-tooltip {
  font-size: 14px;
  background-color: #ffffff26;
  border-radius: 10px;
  padding: 18px 18px;
  border: 1px solid #ffffff32;
  position: absolute;
  transition: 0.1s top linear;
  will-change: top;
  margin: 0 10px 0 40px;
  left: 0;
  right: 0;

  @media only screen and (max-width: 1000px) {
    display: none;
  }

  .header {
    display: flex;
    margin: 0 0 10px;
  }

  img,
  .logo-placeholder {
    width: 36px;
    height: 36px;
  }

  h5 {
    margin: 0.5em 10px 0;
    font-size: 1.3em;
    font-weight: 500;
    flex-grow: 1;
  }
}
