.filter-control {
  display: flex;
  // grid-template-columns: auto auto auto;
  padding: 0 0 20px;
  font-size: 18px;
}

.order-control {
  text-align: center;
  user-select: none;
}

.sort {
  margin: 0 10px;
  box-sizing: border-box;
  background-color: #ffffff0a;
  border-radius: 10px;
  padding: 2px 5px 2px 5px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  color: #eee;
  border: 1px solid #77777787;
  height: 100%;
  transition: 0.2s all ease;
  button {
    border: 0px;
    background-color: transparent;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    color: #b0b0b0;
    transition: 0.2s all ease;
    font-size: 16px;

    &.active {
      color: #eee;
      background-color: rgba(68, 100, 240, 0.3);
    }
  }
}

.remote-toggler, .filter-dropdown button {
  background-color: #ffffff0a;
  border-radius: 10px;
  padding: 2px 5px 2px 5px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  color: #eee;
  border: 1px solid #77777787;
  height: 100%;
  font-size: 16px;
  transition: 0.2s all ease;
  &:hover {
    box-shadow: 0px 0px 4px 0px #ffffff21;
  }
}

.remote-toggler {
  padding-right: 15px;
  margin: 0 10px;
  .light {
    padding: 0 9px 5px;
    font-size: 40px;
    margin: -10px -5px;
  }

  &:hover {
    box-shadow: 0px 0px 4px 2px rgb(255 255 255 / 33%);
  }

  &.remote-only-true {
    color: #013076;
    background-color: #eee;

    .light {
      color: #18b018;
      text-shadow: 0px 0px 1px #24b925, 0px 0px 2px #36ce36, 0px 0px 3px #3adb3a;
    }
  }
}

.filter-dropdown {
  position: relative;
}
.filter-dropdown button {
  svg {
    padding: 0 5px 0 5px;
  }
  &.active {
    background-color: #fff3;
  }
}
