.tech-stack-filters {
  width: 400px;
  box-shadow: -1px 2px 10px 2px #4443;
  margin: 10px auto 0;
  position: absolute;
  z-index: 9;
  color: #eee;
  font-size: 14px;
  background-color: #343541;
  border-radius: 10px;
  padding: 18px 18px;
  border: 1px solid #ffffff32;

  @media only screen and (max-width: 768px) {
    right: 0;
    left: 0;
    top: 100px;
    height: 100vh;
    max-width: 100%;
  }

  .stack {
    display: inline-block;
    font-size: 0.8em;
    color: #fff;
    padding: 3px 0.6em;
    border-radius: 20px;
    margin: 0.2em;
    //color: #fff;
    font-weight: 700;
    filter: grayscale(0.3) contrast(0.7) brightness(0.8);
    cursor: pointer;
    transition: linear 0.1s all;
    outline: 0px solid;
    user-select: none;
    &:hover {
      outline: 3px solid;
    }
    &.active {
      filter: grayscale(0) contrast(1) brightness(1);
      opacity: 0;
    }
  }
  .available .active {
    pointer-events: none;
  }
  .chosen .stack {
    opacity: 1;
  }
  .chosen {
    min-height: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .stack{
      svg{
        vertical-align: middle;
        display: inline-block;
        padding: 0 0 2px;
      }
    }
    .empty{
        user-select: none;
        color: #777;
    }
    > svg {
      vertical-align: middle;
      padding: 5px;
    }
  }
  hr {
    border: none;
    border-bottom: 1px solid #dee1e5;
  }
}
