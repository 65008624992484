.opening-full {
  display: block;
  font-size: 18px;
  --top-bar: 0;

  position: fixed;
  top: 0;
  height: 100vh;
  overflow-y: scroll;
  width: 100%;
  overscroll-behavior: contain;
  z-index: 10;
  padding-top: var(--top-bar);

  &.error-404{
    .close{
      background-color: #0950ba;
      color: #fff;
      padding: 3px 6px;
      font-size: 23px;
      margin: 0 auto;
      font-weight: normal;
    }
  }

  &.modal-mode {
    // --top-bar: 50px;
    backdrop-filter: blur(2px) brightness(0.5);
    @media only screen and (max-width: 768px) {
        // --top-bar: 98px;
    }
  }

  &.solo-mode {
    // --top-bar: 30px;
    background-color: #e8ebee;
  }

  .grid {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    // padding-top: calc(var(--top-bar));
    border-radius: 20px;
    gap: 0 15px;
  }
  .opening-title {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: calc(var(--top-bar) * -1);
    // background-color: #fff;
    padding: 10px 0 0;
  }

  .buttons {
    display: flex;
  }

  .close {
    background-color: #eee;
    border: 0;
    border-radius: 4px;
    font-size: 19px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
  }

  .apply button {
    background-color: #0950ba;
    border-radius: 4px;
    padding: 7px;
    text-decoration: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    display: flex;
    cursor: pointer;
    color: #fff;
    height: 100%;
    border: none;
    font-weight: bold;
    margin: 0 10px;
    border-bottom: 4px solid #05367f;
  }
  .opening-main {
    padding: 20px;
    background-color: #232633;
    font-size: 20px;
    border-radius: 10px;

    svg {
      vertical-align: bottom;
      margin: 0 0.2em;
    }

    h1,
    h4 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    h1 {
      font-size: 1.2em;
      font-weight: 500;
      margin: 0;
    }

    h4 {
      font-size: 0.95em;
      margin: 0;
      font-weight: normal;
    }
  }

  .opening-info {
    align-self: center;
    display: flex;
    width: 100%;
    font-size: 0.9em;
    margin: 7px 0;
    span {
      margin: 0 0.6em 0 0;
    }
    svg {
      margin: 0 0.2em 0 0;
    }
    .seen {
      color: #008a3c;
      border-radius: 5px;
      margin: 0 0.2em 0 0;
      svg {
        margin: 0;
      }
    }
    .salary-range {
      color: #008a3c;
      font-weight: bold;
      // margin: -4px 0.6em -4px -1px;
      // border-radius: 5px;
    }
  }

  .stacks {
    padding: 0 1em 0 0;
  }

  .stack {
    display: inline-block;
    font-size: 0.8em;
    color: #fff;
    padding: 3px 0.6em;
    border-radius: 20px;
    margin: 0.2em;
    //color: #fff;
    font-weight: 700;
  }

  .company-info {
    display: flex;
    grid-template-columns: auto auto;
    margin: 10px 0 0;
    border: 1px solid #dee1e5;
    border-width: 1px 0 1px;
    // border-radius: 10px;
    padding: 5px;
    max-width: 600px;
    .header {
      padding: 0 15px 10px;
    }
    .header h5 {
      font-size: 1em;
      margin: 0 0 5px;
      font-weight: 400;
    }
    p {
      font-size: 15px;
    }
    .logo {
      width: 60px;
      height: 60px;
      margin: 0;
    }
  }
  .job-description {
    font-size: 16px;
    max-width: 70ch;
    margin: 20px 0 0;
    overflow-wrap: break-word;
    line-height: 1.25em;
    p {
      margin: 0 0 15px;
    }
    img {
      max-width: 100%;
    }
  }
}
