body {
    background-color: #0f121f;
    color: #dedede;
    font-size: 28px;
    // padding: 50px 0 0;
}

p {
    margin: 0;
}

a {
    text-decoration: none;
    color: #168eff;
}

.hidden {
    display: none !important;
}

select,
input, .fake-box {
    font-size: 16px;
    margin: 0;
    border-radius: 4px;
    padding: 0.3em 0.5em;
    border: 1px solid #7a7b7c;
    box-sizing: content-box;
    border: 0;
    background-color: rgb(255 255 255 / 20%);
    color: #fff;
}

.container {
    max-width: 1050px;
    margin: 0 auto;
}

.intro-header{
    position: relative;
}

main {
    margin: 0 auto;
    display: grid;
    grid-template-columns: minmax(270px, 1fr) minmax(auto, 1000px) minmax(0px, 1fr);
    overflow: hidden;
    @media only screen and (max-width: 1000px) {
        grid-template-columns: 0 auto 0;
    }
}

article {
    // max-width: 1000px;
    margin: 0 auto;
    padding: 70px 0 0;
    position: relative;
    width: 100%;
    // padding: 70px 0 20px;
    @media only screen and (max-width: 1000px) {
        padding: 120px 0 0;
    }
}

aside {
    position: relative;
}

.job-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-size: 18px;
    padding: 20px 0;
    max-width: 1090px;
    margin: 0 auto;

    @media only screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
        gap: 0 10px;

        h2 {
            font-size: 20px;
        }

        .repetition {
            display: none;
        }
    }

    > div {
        padding: 0 1em;
    }

    h2 {
        margin: 1em 0 0.5em;
        font-weight: 500;
        font-size: 22px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        margin: 0 0 5px;
        cursor: pointer;
        color: #168eff;

        &:hover {
            text-decoration: underline;
        }
    }

    .repetition {
        color: #999;
    }
}

footer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    font-size: 16px;
    text-align: center;
    margin: 20px auto 0;
    padding: 10px;
    align-items: center;
    max-width: 1090px;
    gap: 15px;
    // background-color: #1d2857;
    color: #777;

    @media only screen and (max-width: 1000px) {
        grid-template-columns: 1fr;
        justify-items: center;
    }

    .btn-area {
        display: flex;
        padding: 0 15px;
    }

    button {
        font-size: 16px;
        background-color: #fff;
        color: #1067d2;
        border-radius: 10px;
        padding: 7px 15px 4px;
        text-transform: uppercase;
        align-items: center;
        font-weight: 600;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        transition: 0.2s all ease;
        border: 1.5px solid #1067d2;
        letter-spacing: -0.5px;
        margin: 0 10px 0 0;

        svg {
            vertical-align: top;
        }

        &:hover {
            background-color: #1067d2;
            color: #fff;
        }
    }

    .company-add {
        font-size: 17px;
        line-height: 0.9em;
    }

    .reduced {
        font-size: 11px;
    }
}