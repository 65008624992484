header {
  background-color: #15192a;
  // background-color: #18293f;
  color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;

  .header-container{
    max-width: 1000px;
    margin: 0 auto;
    padding: 7px 0px 7px;
    display: flex;
    gap: 10px;
    grid-template-columns: auto auto;
    align-items: center;
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    transition: 0.2s top ease;
  }

  &.no-input {
    bottom: 0;
    top: auto;
    position: absolute;
    background-color: transparent;
    max-width: 800px;
    margin: 0px auto 70px;
    .main-question input {
      border-width: 1px 1px 2px;
      padding: 5px 15px;
      font-size: 19px;
    }
    img {
      display: none;
    }
    .app-logo {
      display: none;
    }
  }

  .app-logo {
    justify-content: left;
    padding: 0 10px;
    img{
      width: 40px;
      height: 32px;
      object-fit: cover;
      display: block;
    }
  }

  .main-form{
    flex-grow: 1;
  }

}

.main-question {
  display: grid;
  grid-template-columns: 4fr 3fr;
  gap: 10px 15px;
  font-size: 1em;

  @media only screen and (max-width: 768px) {
    grid-template-columns: auto 1fr;
  }

  .fake-box {
    display: flex;
    align-items: center;
    transition: 0.2s all ease;

    &.location-false {
      .toggler {
        background-color: transparent;
        color: #eee;
        border: 0;
      }
    }

    // &.box-warn-true::after {
    //   content: "";
    //   position: absolute;
    //   z-index: -1;
    //   width: 100%;
    //   height: 100%;
    //   left: 0;
    //   border-radius: 12px;
    //   box-shadow: 0px 0px 7px 5px #ed2c05;
    //   animation: glowLoop 1.5s infinite;
    // }

    @keyframes glowLoop {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0.7;
      }

      100% {
        opacity: 1;
      }
    }

    .toggler {
      display: flex;
      align-items: center;
      color: #58b45a;
      border-radius: 30px;
      padding: 1px;
      margin-right: -7px;
      font-size: 27px;
    }

    svg {
      vertical-align: middle;
      font-size: 0.6em;
    }

    input {
      background-color: transparent;
      margin: 0;
      border: none;
      width: 100%;
      color: inherit;
      outline: none;
      border-radius: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
