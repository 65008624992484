.skew-bg {
  transform: skewY(-3deg);
  margin: -50px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, #053477, #0a274a 70%, #141416);
}

.intro-header {
  // background: linear-gradient(132deg, #2261b9, #0073ff 50%, #04b2ff);
  // background: linear-gradient(132deg, #054093, #013e89 50%, #00638e);
  .bg {
    position: absolute;
    right: 0;
    top: 68%;
    bottom: -60px;
    left: 0;
    overflow: hidden;
    clip-path: ellipse(105% 45% at center right);
    background-size: cover;
    opacity: 0.9;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}

.intro {
  position: relative;
  transition: 0.2s all ease;
  max-height: 100vh;
  overflow: hidden;
  padding: 0 0 150px;
  color: #fff;
  max-width: 1050px;
  margin: 0 auto;
  position: relative;

  .news {
    font-size: 0.5em;
    background-color: #0000004d;
    padding: 5px 8px;
    border-radius: 5px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0 0 103px;

    .side-dog {
      display: none;
    }

    .container {
      padding: 0 1em;
    }
  }

  .app-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 25px;
    h1 {
      margin: 0;
      font-size: 1.2em;
      color: #f0f4fb;
      text-align: center;
      text-shadow: 0px 1px 5px #333;
      padding: 10px;
    }
    img{
      width: 50px;
    }
  }

  &.has-results {
    max-height: 0;
    padding: 0;
  }

  .callout {
    line-height: 1.06;
    font-weight: normal;
    letter-spacing: -0.02em;
    text-align: center;
    font-size: 2em;
    margin: 30px 0 15px;
  }

  .container {
    margin: 30px auto 15px;
  }

  h4 {
    font-size: 20px;
    margin: 1em 0 1em;
    font-weight: 400;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    color: #ffffff;
    letter-spacing: -0.007em;
  }

  h5 {
    font-size: 0.7em;
    margin: 0 0 0.2em;
    font-weight: 500;
  }

  .content {
    z-index: 5;
    position: relative;
    text-shadow: 0px 1px 5px #3333;
    padding: 0 15px;
  }

  .side-dog {
    position: absolute;
    right: 100px;
    top: 150px;
    bottom: 0;
    margin: 0 0 50px;
    z-index: 2;
    img {
      object-fit: contain;
      height: 100%;
      padding: 0 0px 20px;
      box-sizing: border-box;
    }
    .shadow {
      position: absolute;
      top: 75%;
      left: 12px;
      width: 100%;
      bottom: 5px;
      clip-path: ellipse(40% 49% at center center);
      background-image: radial-gradient(rgb(0 0 0 / 33%), #ffffff00);
      z-index: -1;
    }
  }
}
