.company-logo, .logo-placeholder {
    width: 56px;
    height: 56px;
    align-self: center;
    margin: 0 auto;
    border-radius: 20%;
    object-fit: contain;
}

.logo-placeholder{
    font-size: 40px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(132deg, #2261b9, #0073ff 50%, #04b2ff);
    background: linear-gradient(132deg, hsl(var(--hue), 69%, 15%), hsl(var(--hue), 69%, 15%) 50%, hsl(var(--hue), 69%, 15%));
    color: #eee;
    // -webkit-background-clip: text;
    // background-clip: text;
    // color: transparent;
}

.company-tooltip .logo-placeholder{
    font-size: 28px;
    padding: 3px;
}